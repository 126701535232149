exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-frequently-asked-questions-tsx": () => import("./../../../src/pages/frequently-asked-questions.tsx" /* webpackChunkName: "component---src-pages-frequently-asked-questions-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-litepaper-tsx": () => import("./../../../src/pages/litepaper.tsx" /* webpackChunkName: "component---src-pages-litepaper-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-rookies-tsx": () => import("./../../../src/pages/rookies.tsx" /* webpackChunkName: "component---src-pages-rookies-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-tokenomics-tsx": () => import("./../../../src/pages/tokenomics.tsx" /* webpackChunkName: "component---src-pages-tokenomics-tsx" */),
  "component---src-pages-unsubscribe-tsx": () => import("./../../../src/pages/unsubscribe.tsx" /* webpackChunkName: "component---src-pages-unsubscribe-tsx" */),
  "component---src-pages-what-is-hedgeblock-tsx": () => import("./../../../src/pages/what-is-hedgeblock.tsx" /* webpackChunkName: "component---src-pages-what-is-hedgeblock-tsx" */),
  "component---src-templates-newsletter-category-tsx": () => import("./../../../src/templates/NewsletterCategory.tsx" /* webpackChunkName: "component---src-templates-newsletter-category-tsx" */),
  "component---src-templates-newsletter-content-tsx": () => import("./../../../src/templates/NewsletterContent.tsx" /* webpackChunkName: "component---src-templates-newsletter-content-tsx" */),
  "component---src-templates-newsletter-list-tsx": () => import("./../../../src/templates/NewsletterList.tsx" /* webpackChunkName: "component---src-templates-newsletter-list-tsx" */)
}

